import DOMPurify from 'isomorphic-dompurify'
import styled from 'styled-components'
import Paragraph from '../common/Paragraph'
import { CLASSES_DESCRIPTION, CLASSES_DOC_EMBEDDED_URL } from './strings'

const ClassesDocContainer = styled.div`
  width: 100%;
  height: 500px;
  margin: 2em 0;
  text-align: center;

  > iframe {
    width: 125%;
    height: 125%;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: scale(0.8);
    transform-origin: top left;
  }

  @media only screen and (max-width: 767px) {
    height: 700px;
  }
`

const EquippingClasses = () => {
  return (
    <>
      <Paragraph>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(CLASSES_DESCRIPTION),
          }}
        />
      </Paragraph>
      <ClassesDocContainer>
        <iframe title="CrossLife Classes" src={CLASSES_DOC_EMBEDDED_URL}>
          Loading…
        </iframe>
      </ClassesDocContainer>
    </>
  )
}

export default EquippingClasses
