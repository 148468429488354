const LEADERSHIP_DATA = [
  {
    id: 'james-lee',
    name: 'James Lee',
    role: 'Pastor',
    image: 'https://assets.crosslifebiblechurch.org/pastor-james-3.jpg',
    biography: [
      "Hello, my name is James Lee, and I have been serving as the pastor of CrossLife Bible Church ever since it began in 2011. I am married to my beautiful wife, Lucy, who is God's gift to me!",
      "I grew up going to church my entire life, but it was some time in middle school when I came to understand the Gospel. However, a lot of my spiritual growth began at UC Berkeley when I attended a church where the Word of God was preached. I began to shed away my aspirations for monetary success and eventually developed a conviction to go into full-time ministry. After college, I immediately enrolled myself at The Master's Seminary where I sat under the teaching of some of the most brilliant and godly men. I cannot express my gratitude for all the ways in which my professors have counseled, encouraged, and supported me.",
      "When I completed my studies, I went out for a year to do missionary work in Taipei, Taiwan. Being a Korean-American, I didn't know any Chinese and even my Korean left a lot to be desired. However, the Lord was faithful in helping me learn enough of the language to share the Gospel.",
      'After I returned from Taiwan, a small team and I planted a church in Irvine with the support and prayers of local pastors, my close seminary professors, family, and friends, and by the grace of God, the Gospel went forth and produced much fruit! Some came to salvation, while others grew in their faith. Today I am truly blessed with a congregation that loves our Triune God and desires to live out the very Gospel that saved them.',
    ],
    education: [
      'Bachelor of Science in Chemical Engineering and Nuclear Engineering from UC Berkeley (2005)',
      "Master of Divinity from The Master's Seminary (2008)",
    ],
    favoriteVerse:
      '“Restore to me the joy of your salvation, and uphold me with a willing spirit. Then I will teach transgressors your ways, and sinners will return to you” (Psalm 51:12-13 ESV)',
    hobbies: ['Surfing', 'Reading'],
  },
]

export default LEADERSHIP_DATA
