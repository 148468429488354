/**
 * Church Membership section
 */
export const CHURCH_MEMBERSHIP_DESCRIPTION = `Our membership classes are designed to teach the fundamentals of the Christian faith, including the Trinity, the inspiration of Scripture, and the work and the person of Jesus Christ. We also cover our doctrinal distinctives and the biblical principles by which we govern our church. If you're thinking about committing to CrossLife, we encourage you to take the classes to see if this is the local community in which you would like to live out your faith. The membership classes are divided into three lectures and cover the specific topics listed below.`
export const CHURCH_MEMBERSHIP_UPCOMING_DATES_CONTENT =
  'Saturdays on February 1, February 8, and February 15. Check social media for updates!'
export const CHURCH_MEMBERSHIP_DURATION_CONTENT = '3 classes | 10AM - 1PM'
export const CHURCH_MEMBERSHIP_LOCATION_CONTENT = 'Church'
export const CLASS_INFO_LABEL = 'Details'
export const CLASS_OUTLINE_LABEL = 'Outline'
export const REQUIRED_MATERIALS_LABEL = 'Required Materials'

export type ClassTopic = {
  topic: string
  subtopics: string[]
}

export const CLASS_CONTENT = [
  {
    classDate: '02/01/2025',
    classTopics: [
      { topic: 'The Purpose of Church Membership', subtopics: [''] },
      {
        topic: 'The Doctrines of the Christian Faith',
        subtopics: ['The Bible', 'God', 'The Son', 'The Spirit', 'Salvation'],
      },
    ],
  },
  {
    classDate: '02/08/2025',
    classTopics: [
      {
        topic: 'Church Life',
        subtopics: [
          'Church Polity',
          'Elders and Deacons',
          'Worship (Expositional Preaching and Offering)',
          'Ordinances of the Church (Baptism and Communion)',
        ],
      },
    ],
  },
  {
    classDate: '02/15/2025',
    classTopics: [
      {
        topic: 'Church Life (cont.)',
        subtopics: ['Spiritual Gifts', 'Church Discipline'],
      },
    ],
  },
]

/**
 * Classes section
 */
export const ClassesDocUrl =
  'https://docs.google.com/document/d/1rZIUXVU36v2zqYy2-xrSYDFCAgh6RwzZadTRjGPjo7Y/edit?usp=sharing'
export const CLASSES_DESCRIPTION = `At CrossLife, we're excited to offer a variety of equipping classes designed to help you grow and deepen your faith in Christ. Feel free to check out the document below or click <a href="${ClassesDocUrl}">this link</a> to learn more about our classes and how to sign up!`
export const CLASSES_DOC_EMBEDDED_URL =
  'https://docs.google.com/document/d/e/2PACX-1vQ2BI7Iuzm5prQEFx8zFS9xhbuKOxl7jYa-qQX-IZDO2v18Hni45Yk6kNsRW1mJ5CgaLp4Pw8PZ7Keo/pub?embedded=true'
